:root {
    --fc-button-bg-color: #E55A34;
    --fc-button-border-color: #321006;
}

.fc .fc-view-harness {
    @apply bg-white text-gray-900;
}

.fc-event {
    min-height: 75px;
}

.fc-timegrid-event {
    @apply cursor-pointer;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    @apply cursor-pointer;
}

.fc-v-event .fc-event-title {
    padding-left: 6px;
}
/* 
.fc-timegrid-slots {
    background: white;
} */

.fc-scroller {
    overflow: hidden;
}

/* .fc-col-header {
    background: white;
} */


.fc .fc-toolbar-title {
    font-size: 1.5rem;
}

.fc-timegrid-event .fc-event-time {
    margin-bottom: 1px;
    font-size: 0.7rem !important;
    text-align: center;
    background-attachment: fixed;
    background-size: cover;
}

.fc-media-screen .fc-timegrid-event {
    border: none;
}


/* .fc-theme-standard .fc-scrollgrid {
    border: none !important;
} */

.fc-col-header, .fc-timegrid-body  {
    width: 100% !important;
}

.fc-event-main-frame {
    font-size: 0.6rem;
}

.fc-event-title-container {
    padding-top: 0.5rem;
}


/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
