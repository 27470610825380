@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
}

html,
body {
  @apply h-full bg-gray-900 text-white font-sans;
  padding: 0;
  margin: 0;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.container {
  @apply max-w-7xl mx-auto px-4 sm:px-6 md:px-8;
}

#nprogress .spinner {
  display: none !important;
}

.w-inherit {
  width: inherit;
}

#course-video {
  @apply aspect-video;
}

#__next {
  @apply h-full;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.thumbnail__container {
  position: relative;
  display: inline-block;

  background-color: rgb(229 90 52);
}

  .thumbnail__container:before,
  .thumbnail__container:after {
    content: '';
    display: block;
    background-color: rgb(229 90 52);
    width: 8px;
    height: 8px;
    position: absolute;
    transition: all .15s ease;
  }

  .thumbnail__container:before {
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: rotate(-45deg) scale(0);
  }

  .thumbnail__container:after {
    right: 0;
    bottom: 0;
    transform-origin: bottom right;
    transform: rotate(45deg) scale(0);
  }

  .stream__thumbnail {
    display: block;
    transform: translate(0, 0);
    transition: all .15s ease;
    position: relative;
    z-index: 2;
  }

.stream:hover .thumbnail__container > .stream__thumbnail {
  transform: translate(6px, -6px);
}

.stream:hover .thumbnail__container:before {
  transform: rotate(-45deg) scale(1);
}

.stream:hover .thumbnail__container:after {
  transform: rotate(45deg) scale(1);
}

.tagCloud::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
.tagCloud::-webkit-scrollbar-thumb {
  @apply bg-white bg-opacity-40;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}


.platinum-invite {
	background: linear-gradient(-45deg, #E55A34, #B9309C, #E55A34, #B9309C);
	background-size: 1000% 1000%;
	animation: gradient 8s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.news-article p {
  @apply my-3;
}

.news-article ul {
  @apply list-disc pl-5;
}

.news-article li {
  @apply my-2;
}

.post-editor a {
  @apply text-primary;
}

.post-editor .DraftEditor-root {
  @apply outline-0 p-3 text-lg min-h-[8rem];
}

.post-editor .DraftEditor-root a {
  @apply text-primary;
}

.discussion-post a {
  @apply text-primary;
}

.post-content p {
  @apply break-words;
  word-break: break-word;
}

.formatted-text {
  @apply break-words;
  word-break: break-word;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: white
}

[data-simplebar] {
  height: 100% !important;
}